<template>
	<div class="publish_info">
		<top-search :diyJump="true" @changeMode="changeMode" :preselection="preselection" :is_evaluate="true" ></top-search>
		<div class="publish_content">
			<div class="desc">
				<div class="tit">信息</div>
				<div class="right">
					<div class="top">
						<textarea class="shuru" placeholder="描述" v-model="goods_name" @keydown="keyDown($event,'textarea',0)"></textarea>
						<el-upload ref="upload" :data="{token:token}" :show-file-list="false" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } "  :on-exceed="handleExceed" :limit="5">
							<div v-if="current_mode == 1" class="add el-icon-plus"></div>
						</el-upload>
					</div>
					<div class="imgs" v-if="current_mode == 1" >
						<div class="img_item" v-for="(item,index) in imgList" :key="index">
							<el-image :src="$img(item)" fit="cover" :preview-src-list="imagesList"></el-image>
							<div @click="deleteImg(index)" class="el-icon-error"></div>
						</div>
					</div>
				</div>
			</div>
			<div v-loading="loading" v-if="goods_category_list.length" class="condition_item">
				<div class="tit">分类</div>
				<div class="right">
					<div class="item" v-for="(item,index) in goods_category_list" :key="index" :class="current_goods_category == index?'active':''" @click="changeGoodsCategory(item,index)">{{item.category_name}}</div>
				</div>
			</div>
			<div v-loading="attr_loading">
				<div class="condition_item" v-for="(item,index) in attr_list" :key="index" >
					<div class="tit">{{item.attr_name}}</div>
					<div class="right">
						<div class="item" v-for="(aItem,aIndex) in item.attr_value" :class="aItem.select ?'active':''" @click="chooseAttrData(item,aItem)">{{aItem.attr_value_name}}</div>
					</div>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">{{current_mode==1?'价格':current_mode==2?'预算':'预估'}}</div>
				<input class="shuru" type="number" min="0" @keydown="keyDown($event,'input',1)" :placeholder="current_mode==1?'价格':current_mode==2?'预算':'预估'" v-model="price" />
			</div>
			<div class="form_item">
				<div class="tit">姓名</div>
				<input class="shuru" type="text" placeholder="姓名" @keydown="keyDown($event,'input',2)" v-model="contact_name" />
			</div>
			<div class="form_item">
				<div class="tit">电话</div>
				<input class="shuru" type="number" placeholder="电话" @keydown="keyDown($event,'input',3)" v-model="contact_mobile" />
			</div>
			<div class="action_area">
				<div class="btn" @click="publishInfo(0)">存草稿</div>
				<div class="btn" @click="publishInfo(1)">发布</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { goodsCategoryLists, attrDetail, addGoods, myGoodsDetail, editGoods } from '@/api/resource.js'
	import topSearch from '@/components/top_search';
	import { Select } from 'element-ui';
	import Config from '@/utils/config';
	import {
		mapGetters
	} from "vuex"
	export default {
		components: {
			topSearch
		},
		computed: {
			...mapGetters(["token"])
		},
		data() {
			return {
				current_mode:1,
				current_category:0,
				preselection:'',
				goods_category_list:[],
				current_goods_category:0,
				attr_list:[],
				current_attr:0,
				category_id:'',//分类id
				goods_name:'',
				attr_loading:true,
				price:'',
				contact_name:'',
				contact_mobile:'',
				uploadActionUrl: Config.baseUrl + 'mling/api/membergoods/uploadimagetoalbum', //表情图标
				imgList:[],
				goods_id:'',
				goods_detail:{},
				loading:true,
				is_add:true,
				imagesList:[]
			}
		},
		created(){
			this.preselection = this.$route.query.mode || '';
			if(this.$route.query.mode){
				this.current_mode = this.$route.query.mode;
			}
			this.goods_id = this.$route.query.goods_id || '';
			if(this.$route.query.goods_id){
				this.is_add = false;
				this.getGoodsDetail();
			}else{
				this.getGoodsCategoryLists();
			}
		},
		watch: {
			
		},
		methods: {
			
			keyDown(e,mode='',index){
				if(e.keyCode == 13 && mode == 'textarea') event.preventDefault();
				if(e.keyCode == 40 || e.keyCode == 13){
					if(index>=3){
						document.getElementsByClassName('shuru')[0].focus();
					}else{
						document.getElementsByClassName('shuru')[index+1].focus();
					}
				}
			},
			getGoodsDetail(){
				myGoodsDetail({
					goods_id:this.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						this.goods_detail = res.data;
						this.goods_name = res.data.goods_name;
						this.price = res.data.price;
						this.contact_name = res.data.contact_name;
						this.contact_mobile = res.data.contact_mobile;
						this.goods_detail['goods_attr_parse'] = JSON.parse(res.data.goods_attr_format);
						this.category_id = res.data.category_id_1;
						if(this.current_mode==1){
							if(this.goods_detail.goods_image){
								this.imgList = this.goods_detail.goods_image.split(',')
								this.imagesList = this.imgList.map(item=>{
									return this.$img(item)
								})
							}
						}
						if(this.attr_list.length){
							var select_value_ids = [];
							this.goods_detail.goods_attr_parse.forEach(item=>{
								select_value_ids.push(item.attr_value_id);
							})
							this.attr_list.forEach(item=>{
								item.attr_value.forEach(aItem=>{
									if( select_value_ids.indexOf(aItem.attr_value_id) != -1 ){
										aItem.select = true;
									}
								});
							})
						}
						this.getGoodsCategoryLists();
					}
				})
			},
			publishInfo(goods_state){
				this.attr_list.forEach((item)=>{
					item.has_select = false;
					item.attr_value.forEach((vItem,vIndex)=>{
						if(vItem.select) item.has_select = true;
					})
				})
				if(!this.goods_name){
					this.$message.error('请输入信息');
					return;
				}
				var phonereg = /^1[3-9]\d{9}$/;
				// if(this.current_mode == 1 && !this.imgList.length){
				// 	this.$message.error('请上传图片');
				// 	return;
				// }
				try{
					this.attr_list.forEach((item,index)=>{
						if(!item.has_select){
							this.$message.error('请选择'+item.attr_name);
							throw new Error()
						}
					})
				}catch(e){
					return;
					//TODO handle the exception
				}
				if(!this.price){
					this.$message.error('请填写价格');
					return;
				}
				if(!this.contact_name){
					this.$message.error('请填写姓名');
					return;
				}
				if(!this.contact_mobile){
					this.$message.error('请填写电话');
					return;
				}
				if(!phonereg.test(this.contact_mobile)){
					this.$message.error('电话格式不正确');
					return;
				}
				var goods_attr_format = [];
				this.attr_list.forEach((item,index)=>{
					item.attr_value.forEach((vItem,vIndex)=>{
						if(vItem.select){
							goods_attr_format.push({'attr_class_id':item.attr_class_id,'attr_id':item.attr_id,'attr_name':item.attr_name,'attr_value_id':vItem.attr_value_id,'attr_value_name':vItem.attr_value_name,'sort':0})
						}
					})
				})
				// if(!goods_attr_format.length && this.attr_list.length){
				// 	this.$message.error('请选择分类');
				// 	return;
				// }
				if(this.is_add){
					addGoods({
						goods_class:this.current_mode==1 ? 5 : ( this.current_mode == 2 ? 6 : 7 ),
						goods_name:this.goods_name,
						category_id:this.category_id,
						goods_state:goods_state,
						contact_name:this.contact_name,
						contact_mobile:this.contact_mobile,
						goods_attr_format:JSON.stringify(goods_attr_format),
						goods_image:this.imgList.toString(),
						price:this.price
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'发布成功'})
							setTimeout(()=>{
								if(this.current_mode==1){
									this.$router.push('/resource-'+res.data.goods_id)
								}else if(this.current_mode==2){
									this.$router.push('/demand-'+res.data.goods_id)
								}else{
									this.$router.push({ path:'/member/index',query:{my_current:6,current_role:'seller'}})
								}
							},500)
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					editGoods({
						goods_class:this.current_mode==1 ? 5 : ( this.current_mode == 2 ? 6 : 7 ),
						goods_name:this.goods_name,
						category_id:this.category_id,
						goods_state:goods_state,
						contact_name:this.contact_name,
						contact_mobile:this.contact_mobile,
						goods_attr_format:JSON.stringify(goods_attr_format),
						goods_image:this.imgList.toString(),
						price:this.price,
						goods_id:this.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'修改成功'})
							setTimeout(()=>{
								if(this.current_mode==1){
									this.$router.push('/resource-'+res.data.goods_id)
								}else if(this.current_mode==2){
									this.$router.push('/demand-'+res.data.goods_id)
								}else{
									this.$router.push({ path:'/member/index',query:{my_current:6,current_role:'seller'}})
								}
							},500)
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
			},
			deleteImg(index){
				this.imgList.splice(index,1)
				this.imagesList = this.imgList.map(item=>{
					 return this.$img(item)
				})
			},
			handleExceed(file, fileList) {
				// 图片数量大于6
				this.$message.warning('上传图片最大数量为5张');
			},
			
			handleSuccess(file, fileList) {
				this.imgList = this.imgList.concat(file.data.pic_path);
				this.imagesList = this.imgList.map(item=>{
					 return this.$img(item)
				})
				// this.imgList = [];
			},
			chooseAttrData(attrData,aItem){
				if(attrData.attr_type==1){
					//单选
					attrData.attr_value.forEach(item=>{
						item.select = false;
					})
					aItem.select = true;
				}else{
					//多选
					if(aItem.select){
						aItem.select = false;
					}else{
						aItem.select = true;
					}
				}
				
			},
			changeGoodsCategory(item,index){
				this.attr_loading = true;
				this.category_id = item.category_id;
				this.current_goods_category = index;
				this.getAttrdetail(item.attr_class_id)
			},
			getAttrdetail(id){
				attrDetail({
					class_id:id
				})
				.then(res=>{
					if(res.code>=0){
						this.attr_list = [];
						this.attr_list = res.data.attribute_list;
						this.attr_list.forEach(item=>{
							this.$set(item,'attr_value',JSON.parse(item.attr_value_format))
							this.$set(item,'has_select',false)
							item.attr_value.forEach(aItem=>{
								this.$set(aItem,'select',false);
							});
						})
						if(this.goods_detail.goods_id){
							var select_value_ids = [];
							this.goods_detail.goods_attr_parse.forEach(item=>{
								select_value_ids.push(item.attr_value_id);
							})
							this.attr_list.forEach(item=>{
								item.attr_value.forEach(aItem=>{
									if( select_value_ids.indexOf(aItem.attr_value_id) != -1 ){
										aItem.select = true;
									}
								});
							})
						}
						this.attr_loading = false;
					}
				})
				.catch()
			},
			getGoodsCategoryLists(){
				goodsCategoryLists({
					goods_type:this.current_mode==2 ? 'demand':'resource'
				})
				.then(res=>{
					if(res.code>=0){
						if(!this.is_add){
							if(res.data.length){
								this.goods_category_list = res.data;
								this.goods_category_list.forEach((item,index)=>{
									if(item.category_id==this.category_id){
										this.current_goods_category = index;
										this.getAttrdetail(item.attr_class_id);
									}
								})
							}
							this.loading = false;
						}else{
							if(res.data.length){
								this.goods_category_list = res.data;
								this.category_id = res.data[0].category_id;
							}
							this.loading = false;
							if(this.goods_category_list.length){
								this.getAttrdetail(res.data[0].attr_class_id);
							}else{
								this.attr_loading = false;
							}
						}
						
					}
				})
				.catch()
			},
			chooseCategory(index){
				this.current_category = index;
				 /**
					* 1)先让选中的元素滚到可视区域的最左边 scrollLeft
					* 2)接着向右移动容器一半的距离 containWidth / 2
					* 3)最后向左移动item一半的距离 offsetWidth / 2
				*/
				let lastSpot = this.$refs.scrollBox.scrollLeft;
				const nextSpace = 50; //每次移动距离
				let scrollItemTimer = setInterval(() => {
					this.$nextTick(() => {
						let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth; //item
						let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft; //选中的元素滚到可视区域的最左边
						const containWidth = this.$refs.scrollBox.offsetWidth; //容器的宽度
						if(this.$refs.scrollItem[0].offsetLeft == 0){
							var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2; //最终要停留的点
						}else{
							var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft; //最终要停留的点
						}
						if (Math.abs(lastSpot - resultSpot) < nextSpace) {
							clearInterval(scrollItemTimer);
						}
						if (resultSpot >= lastSpot) {
							lastSpot = lastSpot + nextSpace;
						} else {
							lastSpot = lastSpot - nextSpace;
						}
						this.$refs.scrollBox.scrollTo(lastSpot, 0);
					});
				}, 15);
			},
			changeMode(mode) {
				if(this.current_mode == mode) return;
				this.current_mode = mode;
				this.$router.push('/cms/publish_info?mode='+mode);
				this.current_goods_category = 0;
				this.contact_name = '';
				this.contact_mobile = '';
				this.price = '';
				this.goods_name = '';
				this.imgList = [];
				this.goods_id = '';
				this.goods_detail = {};
				this.loading = true;
				this.goods_category_list = [];
				this.attr_list = [];
				this.attr_loading = true;
				this.getGoodsCategoryLists();
			}
		},
	}
</script>
<style lang="scss">
	.el-image-viewer__canvas{
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 80%;
		height: 80%;
		img{
			object-fit: contain;
		}
	}
	.publish_content{
		.el-upload--picture-card{
			width: auto;
			height: auto;
			line-height: 0;
			background-color: transparent;
			border: none;
		}
	}
</style>
<style lang="scss" scoped>
	.publish_info{
		padding-top: 30px;
		.category_lists{
			width: 100%;
			margin-top: 51px;
			display: flex;
			align-items: center;
			overflow: hidden;
			.item{
				white-space: nowrap;
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				margin-right: 58px;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				&.active{
					color: #FF3300;
				}
				.line{
					margin-top: 9px;
					width: 34px;
					height: 3px;
					border-radius: 20px;
					background-color: transparent;
					&.active{
						background-color: #FF3300;
					}
				}
			}
		}
		.publish_content{
			margin-top: 30px;
			background-color: #fff;
			border-radius: 10px;
			padding: 30px;
			.desc{
				display: flex;
				align-items: baseline;
				
				.tit{
					min-width: 62px;
					color: #30373D;
					font-size: 15px;
					line-height: 18px;
					margin-right: 31px;
				}
				.right{
					flex: 1;
					display: flex;
					flex-direction: column;
					.top{
						display: flex;
						align-items: flex-start;
						textarea{
							padding: 10px;
							margin-right: 30px;
							flex: 1;
							height: 71px;
							min-height: 71px;
							max-height: 142px;
							border: none;
							outline: none;
							box-sizing: border-box;
							background-color: #F7F8FB;
							font-size: 13px;
							line-height: 15px;
							border-radius: 5px;
							color: #30373D;
							resize: none;
						}
						textarea::placeholder{
							color: rgba(48, 55, 61, 0.50);
							font-size: 13px;
							line-height: 15px;
							font-family: "PingFang SC";
						}
						.add{
							color: #30373D;
							font-size: 19px;
							line-height: 19px;
							font-weight: 600;
							padding: 26px 29px;
							background-color: #F7F8FB;
							border-radius: 5px;
						}
						
					}
					.imgs{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						.img_item{
							width: 65px;
							height: 65px;
							position: relative;
							margin-top: 15px;
							margin-right: 15px;
							.el-image{
								cursor: pointer;
								width: 100%;
								height: 100%;
								display: block;
								border-radius: 4px;
							}
							
							.el-icon-error{
								cursor: pointer;
								position: absolute;
								right: 0;
								top: 0;
								z-index: 10;
								transform: translate(50%,-50%);
							}
						}
					}
					
				}
			}
			.condition_item{
				display: flex;
				align-items: baseline;
				margin-top: 10px;
				.tit{
					min-width: 62px;
					color: #30373D;
					font-size: 15px;
					line-height: 18px;
					margin-right: 31px;
				}
				.right{
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					.item{
						margin-top: 20px;
						color: #30373D;
						font-size: 13px;
						line-height: 15px;
						padding: 8px 10px 7px 9px;
						border-radius: 5px;
						background-color: #F7F8FB;
						margin-right: 20px;
						cursor: pointer;
						&.active{
							color:#fff;
							background-color: #30373D;
						}
					}
				}
			}
			.form_item{
				margin-top: 30px;
				display: flex;
				align-items: center;
				.tit{
					min-width: 62px;
					color: #30373D;
					font-size: 15px;
					line-height: 18px;
					margin-right: 31px;
				}
				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
				-webkit-appearance: none !important;
				}
				input{
					min-width: 300px;
					background-color: #F7F8FB;
					border-radius: 5px;
					padding: 8px;
					font-size: 13px;
					line-height: 15px;
					border: none;
					outline: none;
				}
				input::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-size: 13px;
					line-height: 15px;
					font-family: "PingFang SC";
				}
			}
			.action_area{
				display: flex;
				align-items: center;
				margin-top: 30px;
				border-top: 1px solid rgba(48, 55, 61, 0.20);
				padding: 30px 0 0;
				justify-content: center;
				.btn{
					width: 116px;
					padding: 7px 0;
					text-align: center;
					color: rgba(48, 55, 61, 0.50);
					font-size: 15px;
					line-height: 18px;
					border-radius: 5px;
					background-color: #F7F8FB;
					margin-right: 30px;
					cursor: pointer;
					&:last-child{
						margin-right: 0;
					}
					&:hover{
						color: #fff;
						background-color: #30373D;
					}
				}
			}
		}
	}
</style>